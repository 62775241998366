<template lang="pug">
    div
        header.header(:class="{admin: $route.meta.layout && $route.meta.layout != 'user'}")
            div.header-block
                div.icon-container
                    burger-icon.header-menu(v-if="$store.getters['search/searchOpened'] == false" @clicked="clickMenu()" :color="$route.meta.layout == 'admin' ? 'white' : 'var(--text-color)'" :hoverExpanded="hoverExpanded")
                    router-link.header-logo(:class="{admin: $route.meta.layout != 'user'}" tag="img" :src="$route.meta.layout != 'user' ? '/img/logo-header.png' : '/img/logo-header.png'" to="/home" @click.native.prevent="$store.commit('search/resetWrapper')")
                searchbar-header(v-if="showSearchBar" :placeholder="$t('homesearch')" @updateValue="search" :key="initSearchBar")
                actions-component.actions(@showNotification="showNotification")
            header-progress-loader(v-if="$store.getters['search/searchOpened'] && !$store.getters['search/allResultLoaded']")
        results-wrapper
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: ['hoverExpanded'],
    components: {
        SearchBar: () => import('skillbase-component/src/components/search-engine/components/SearchBar.vue'),
        SearchbarHeader: () => import('skillbase-component/src/components/common/input/SearchbarHeader.vue'),
        BurgerIcon: () => import('skillbase-component/src/components/common/svg-icon/BurgerIcon'),
        ActionsComponent: () => import('./Header/Actions'),
        ResultsWrapper: () => import('skillbase-component/src/components/search-engine/components/ResultsWrapper'),
        HeaderProgressLoader: () => import('skillbase-component/src/components/common/HeaderProgressLoader'),
    },
    data() {
        return {
            showNotifications: false,
            initSearchBar: 0
        };
    },
    computed: {
        showSearchBar() {
            if (this.$route.meta.layout != 'user') {
                return false;
            }
            if (this.$route.path != '/home') {
                return true;
            }

            return this.$store.getters['search/showHeaderSearchBar'] || this.$store.getters['search/searchOpened'];
        },
        searchIsOpened() {
            return this.$store.getters['search/searchOpened']
        }
    },
    watch: {
        searchIsOpened(newValue, oldValue){
            if(newValue == false){
                this.initSearchBar +=1
            }
        }
    },
    methods: {
        clickMenu() {
            this.$eventBus.$emit('Header:click-menu');
            this.$store.commit('search/resetWrapper');
        },
        showNotification(showNotifications) {
            this.showNotifications = showNotifications;
            this.$store.commit('search/resetWrapper');
            this.$emit("showNotifications", showNotifications)
            this.$forceUpdate();
        },
        search(value) {
            this.$store.dispatch('search/searchEngineInitialSearch', value);
            this.$store.dispatch('search/logSearch', value);
            if (this.$store.getters.isMobile) {
                this.$store.commit('mobile-layout/showSearchEngine', true);
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.header-block {
    display: flex;
}

.header {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px var(--shadow-color);
    z-index: 4000;

    &.admin {
        background-color: var(--black-color);
    }

    .header-block {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 28px;
        width: 100vw;

        .icon-container {
            display: flex;
            align-items: center;
            position: absolute;
            left: 28px;
        }

        .actions {
            display: flex;
            align-items: center;
            position: absolute;
            right: 35px;
        }

        .header-menu {
            height: 20px;
            cursor: pointer;
        }

        .header-logo {
            height: 35px;
            cursor: pointer;
            margin-left: 55px;

            &.admin {
                filter: var(--svg-filter-white-color);
            }
        }

        .header-search-bar {
            width: 40%;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            & :focus {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.progress-bar {
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0px;
}
</style>
